import $ from 'jquery'
import Utilities from '@risk3sixty/utilities'

export default Utilities.Strings

export function copyAryToClipboard(ary, delimiter = ',') {
  const $el = $('<textarea>')
  $el.val(ary.join(delimiter))
  $el.appendTo('body')
  $el[0].select()
  document.execCommand('copy')
  $el.remove()
}

export function getModuleDisplayName(perm) {
  return this.titleCase(
    perm
      .replace('is_', '')
      .replace('_customer', '')
      .replace('asset_mgmt', 'asset management')
      .replace('compliance_calendar', 'compliance calendar')
      .replace('my_controls', 'controls')
      .replace('projects', 'project management')
      .replace('risk_register', 'risk register')
      .replace('wiki', 'policies')
      .replace('vendor_management', 'vendor management'),
    true
  )
}
