import ApiReports from '@/factories/ApiReports'

export default {
  async getAllReportConfigurations(
    { commit, state },
    reset = state.globalReset
  ) {
    if (state.reporting.reports.data.length > 0 && !reset) return

    // pagination not yet split between custom and default reports
    const page = 1 //state.reporting.pagination.reports.page
    const perPage = 9999 //state.reporting.pagination.reports.perPage
    const sorting = state.reporting.sorting.reports
    const { reportConfigurations } =
      await ApiReports.getAllReportConfigurations(page, perPage, sorting)
    commit('SET_REPORTS', reportConfigurations)
  },

  async getReportTypes({ commit, state }, reset = state.globalReset) {
    if (Object.keys(state.reporting.types).length > 0 && !reset) return

    const { types } = await ApiReports.allTypes()
    commit('SET_REPORT_TYPES', types)
  },

  async setCurrentReportType({ commit, state }, typeId) {
    if (state.reporting?.currentType?.id === typeId) return

    const foundType =
      Object.values(state.reporting.types)
        .flat()
        .find((t) => t.id === typeId) || {}
    commit('SET_CURRENT_REPORT_TYPE', foundType)
  },

  async setReportsSorting({ commit, state, dispatch }, { key, direction }) {
    commit('SET_REPORT_SORTING', { key, direction })
    if (state.reporting.reports.data.length > 0)
      dispatch('getAllReportConfigurations', true)
  },

  async setReportsPage({ commit, state, dispatch }, page) {
    commit('SET_REPORTS_PAGE', page)
    if (state.reporting.reports.data.length > 0)
      dispatch('getAllReportConfigurations', true)
  },

  async setReportsPerPage({ commit, state, dispatch }, perPage) {
    commit('SET_REPORTS_PER_PAGE', perPage)
    if (state.reporting.reports.data.length > 0)
      dispatch('getAllReportConfigurations', true)
  },

  async getDistributionGroups(
    { commit, state },
    { reset = state.gloablReset }
  ) {
    if (state.reporting.distributionGroups.length > 0 && !reset) return

    const { distributionGroups } = await ApiReports.getDistributionGroups()
    commit('SET_DISTRIBUTION_GROUPS', distributionGroups)
  },

  async getCurrentDistributionGroup({ commit, state }, id) {
    const [sort, direction] = Object.entries(
      state.reporting.sorting.currentDistributionGroupUsers
    )[0]
    const { distributionGroups } = await ApiReports.getDistributionGroups(
      id,
      sort,
      direction
    )
    commit('SET_CURRENT_DISTRIBUTION_GROUP', distributionGroups[0])
  },

  async clearCurrentDistributionGroup({ commit }) {
    commit('SET_CURRENT_DISTRIBUTION_GROUP', { users: [] })
  },

  async setDistributionGroupMembersSorting(
    { commit, state, dispatch },
    { key, val }
  ) {
    await commit('SET_DISTRIBUTION_GROUP_USER_SORTING', { key, val })
    dispatch(
      'getCurrentDistributionGroup',
      state.reporting.currentDistributionGroup.id
    )
  },

  async getCurrentDistributionGroupChangeLog({ commit }, id) {
    const { changeLog } = await ApiReports.getDistributionGroupChangeLog(id)
    commit('SET_CURRENT_DISTRIBUTION_GROUP_CHANGE_LOG', changeLog)
  },

  async getTeamReportConfigurationChangeLog({ commit }, id) {
    const { changeLog } = await ApiReports.getTeamReportConfigurationChangeLog(
      id
    )
    commit('SET_TEAM_REPORT_CONFIGURATION_CHANGE_LOG', changeLog)
  },
}
