import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async allTypes() {
    const response = await R3sFetch(`/api/1.0/reports/types/all`)
    return await handleFetchResponse(response)
  },

  async getAllReportConfigurations(page = 1, perPage = 30, sorting = {}) {
    const response = await R3sFetch(
      `/api/1.0/reports/all?page=${page || ''}&pageSize=${
        perPage || ''
      }&sorting=${encodeURIComponent(JSON.stringify(sorting))}`
    )
    return await handleFetchResponse(response)
  },

  async getTypeConfiguration(typeId) {
    const response = await R3sFetch(
      `/api/1.0/reports/configuration/get?typeId=${typeId}`
    )
    return await handleFetchResponse(response)
  },

  async getTeamConfigurations(reportTypeId = null) {
    let queryParams = ``
    if (reportTypeId) queryParams += `?reportTypeId=${reportTypeId}`
    const response = await R3sFetch(
      `/api/1.0/reports/team/configurations/get?${queryParams}`
    )
    return await handleFetchResponse(response)
  },

  async saveTeamConfigurations(
    reportConfiguration,
    scheduleConfiguration,
    accessConfiguration
  ) {
    const response = await R3sFetch(
      `/api/1.0/reports/team/configurations/save`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          configuration_id: reportConfiguration.configuration_id,
          report_type_id: reportConfiguration.report_type_id,
          report_name: reportConfiguration.report_name,
          report_description: reportConfiguration.report_description,
          filters: reportConfiguration.filters,
          groupBy: reportConfiguration.groupBy,
          sortBy: reportConfiguration.sortBy,
          outputType: reportConfiguration.outputType,
          orientation: reportConfiguration.orientation,
          is_deleted: reportConfiguration.is_deleted,

          is_recurring: scheduleConfiguration.isRecurring,
          start_date: scheduleConfiguration.startDate,
          recurring_options: scheduleConfiguration.recurringOptions,
          email_data: scheduleConfiguration.emailData,

          permissionsCode: accessConfiguration.permissionsCode,
          permissionsUserList: accessConfiguration.permissionsUserList,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getTeamReportConfigurationChangeLog(configurationId) {
    const response = await R3sFetch(
      `/api/1.0/reports/team/configurations/changelog/get?id=${configurationId}`
    )
    return await handleFetchResponse(response)
  },

  async getReport({ report, isPreview }, signal = null) {
    const response = await R3sFetch(`/api/1.0/reports/get`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        report,
        isPreview,
      }),
      signal,
    })
    return await handleFetchResponse(response)
  },

  async emailReport(
    {
      report,
      fullCircleUserIds,
      distributionGroupIds,
      newEmailAddresses,
      emailSubject,
      emailMessage,
    },
    signal = null
  ) {
    const response = await R3sFetch(`/api/1.0/reports/email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        report,
        fullCircleUserIds,
        distributionGroupIds,
        newEmailAddresses,
        emailSubject,
        emailMessage,
      }),
      signal,
    })
    return await handleFetchResponse(response)
  },

  async emailReportToDistributionGroup(
    distributionGroupId,
    reportConfigurationId
  ) {
    const response = await R3sFetch(
      `/api/1.0/reports/distributiongroups/report/send`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          distributionGroupId,
          reportConfigurationId,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getDistributionGroups(
    id = null,
    userSort = null,
    userSortDirection = null
  ) {
    const response = await R3sFetch(
      `/api/1.0/reports/distributiongroups/get?id=${id ? id : ''}&userSort=${
        userSort ? userSort : ''
      }&userSortDirection=${userSortDirection ? userSortDirection : ''}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateDistributionGroup(distributionGroup) {
    const response = await R3sFetch(`/api/1.0/reports/distributiongroups/put`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(distributionGroup),
    })
    return await handleFetchResponse(response)
  },

  // upserts the specific userIds as members of distribution group, removing any others
  async createUpdateDistributionGroupMembers(
    distributionGroupId,
    distributionGroupMemberIds
  ) {
    // {
    //   distribution_group_id: 1,
    //   userIds: [1, 2, 3],
    // }
    const response = await R3sFetch(
      `/api/1.0/reports/distributiongroups/members/put`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          distributionGroupId: distributionGroupId,
          userIds: distributionGroupMemberIds,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getDistributionGroupChangeLog(distributionGroupId) {
    const response = await R3sFetch(
      `/api/1.0/reports/distributiongroups/changelog/get?id=${distributionGroupId}`
    )
    return await handleFetchResponse(response)
  },

  async removeReportFromDistributionGroup(
    distributionGroupId,
    reportConfigurationId
  ) {
    const response = await R3sFetch(
      `/api/1.0/reports/distributiongroups/report/remove`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          distributionGroupId,
          reportConfigurationId,
        }),
      }
    )
    return await handleFetchResponse(response)
  },
}
