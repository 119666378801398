import DashboardDomainFilter from './myControls/DashboardDomainFilter.vue'
import DashboardFrameworkFilter from './myControls/DashboardFrameworkFilter.vue'
import DashboardOwnerFilter from './myControls/DashboardOwnerFilter.vue'
import DashboardScopeFilter from './myControls/DashboardScopeFilter.vue'
import DashboardTagsFilter from './myControls/DashboardTagsFilter.vue'

export default {
  DashboardDomainFilter,
  DashboardFrameworkFilter,
  DashboardOwnerFilter,
  DashboardScopeFilter,
  DashboardTagsFilter,
}
