import Assessments from './assessments'
import Extensions from './extensions'
import Compliance from './compliance'
import Feedback from './feedback'
import Files from './files'
import Inventories from './inventories'
import Jira from './jira'
import MyControls from './mycontrols'
import Program from './program'
import Projects from './projects'
import Questionnaire from './questionnaire'
import Reports from './reporting'
import RiskRegister from './riskregister'
import SelfAssessments from './selfassessments'
import VendorManagement from './vendormanagement'
import Vulnerability from './vulnerability'
import Wiki from './wiki'

export default {
  ...Assessments,
  ...Compliance,
  ...Extensions,
  ...Feedback,
  ...Files,
  ...Inventories,
  ...Jira,
  ...MyControls,
  ...Program,
  ...Projects,
  ...Questionnaire,
  ...Reports,
  ...RiskRegister,
  ...SelfAssessments,
  ...VendorManagement,
  ...Vulnerability,
  ...Wiki,

  APP_NO_LONGER_LOADING(state) {
    state.isLoading = false
  },

  SET_INIT_PROCESSING(state, isProcessing = false) {
    state.isInitProcessing = isProcessing
  },

  SET_GLOBAL_RESET(state, reset = false) {
    state.globalReset = reset
  },

  SET_DARK_MODE(state, isDark = false) {
    state.isDarkMode = isDark
  },

  SET_USERS_IN_PAGE(state, users) {
    state.usersInPage = users
  },

  SET_STICKY_NOTES_DELETED(state, boolean) {
    state.stickyNotesIncludeDeleted = boolean
  },

  SET_OAUTH_BINDINGS(state, bindings) {
    state.oauthBindings = { ...state.oauthBindings, ...bindings }
  },

  IS_VALID_REDIRECTING_HIT(state, isValid) {
    state.isRedirectingValid = isValid
  },

  TOGGLE_LEFT_NAV(state, override = null) {
    state.isLeftNavExpanded =
      override !== null ? override : !state.isLeftNavExpanded
  },

  SET_API_KEYS(state, keys) {
    state.awsKeys = keys
  },

  TOGGLE_STICKY_NOTES(state) {
    state.isStickyNotesOpen = !state.isStickyNotesOpen
  },

  SET_STICKY_NOTES(state, notes) {
    state.team.stickyNotes = notes
  },

  ACKNOWLEDGE_ALERT(state, index) {
    state.alerts.splice(index, 1)
  },

  CHECK_ADMINISTRATION(state) {
    if (!(state.auth || {}).user) return (location.href = '/')

    if (state.auth.user.user_type === 'external') return (location.href = '/')
  },

  CHECK_LOGGED_IN(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn
  },

  SET_ALERTS(state, alerts) {
    state.alerts = alerts
  },

  SET_MAIN_NOTIFICATION(state, message) {
    state.mainNotification = message
  },

  SET_MODULE_PERMISSION_LIST(state, list) {
    state.modulePermissionList = list
  },

  SET_PATH(state, path) {
    state.path = path
  },

  SET_FEATURE_FLAGS(state, features) {
    state.featureFlags = features
  },

  SET_QUEUE_SUMMARY(state, summary) {
    state.queueSummary = summary
  },

  SET_UNREAD_SECURE_MESSAGES(state, ary) {
    state.unreadSecureMessages = ary
  },

  SET_USER_ALERT_PREFS(state, prefs) {
    state.auth.user.alert_prefs = prefs
  },

  SET_ACTIVE_PGP_KEY(state, key) {
    state.pgp.key = key
  },

  SET_SESSION_INFO(state, { user, session, nav, permission, team }) {
    state.auth.user = Object.assign(state.auth.user || {}, user)
    state.auth.permissionCode = permission || state.auth.permissionCode
    state.auth.userNavItems =
      typeof nav === 'undefined' ? state.auth.userNavItems : nav
    state.session = Object.assign(state.session || {}, session)
    state.team =
      typeof team === 'undefined' ? state.team : { ...state.team, ...team }

    state.session.user_comm_prefs = Object.keys(
      state.session?.user_comm_prefs || {}
    )
      .map((namespace) => {
        return state.session?.user_comm_prefs?.[namespace] instanceof Array
          ? state.session.user_comm_prefs[namespace]
          : Object.values(state.session?.user_comm_prefs?.[namespace] || [])
      })
      .reduce((obj, ary) => {
        obj[ary[0].type] = ary
        return obj
      }, {})
  },

  SET_CURRENT_TEAM_FIELD(state, { key, value }) {
    state.session = {
      ...state.session,
      current_team: {
        ...state.session.current_team,
        [key]: value,
      },
    }
  },

  SET_GLOBAL_INTERNAL_USERS(state, users) {
    state.internalUsers = users
  },

  SET_ENUM_DEBOUNCE(state, bool) {
    state.team.enumDebounce = bool
  },

  SET_TEAM_ENUMS(state, enums) {
    state.team.enums = enums
  },

  SET_TEAM_USERS(state, users) {
    state.team.users = users || []
  },

  SET_TEAM_AND_INTERNAL_USERS(state, users) {
    state.team.teamAndInternalUsers = users || []
  },

  SET_TEAM_DISABLED_USERS(state, users) {
    state.team.teamDisabledUsers = users || []
  },

  SET_TEAM_CONTACT_USERS(state, users) {
    state.team.contactUsers = users || []
  },

  SET_TEAM_UPLOAD_PORTAL_USERS(state, users) {
    state.team.uploadPortalUsers = users || []
  },

  // SET_TEAM_POLICY_PORTAL_USERS(state, users) {
  //   state.team.policyPortalUsers = users || []
  // },

  SET_TEAM_DISTRIBUTION_GROUPS(state, distributionGroups) {
    state.team.distributionGroups = distributionGroups || []
  },

  SET_TEAM_USER_MODULE_PERMISSIONS(state, permissions) {
    state.auth.teamUserModulePermissions = permissions
  },

  SET_TAGS(state, tagsAry) {
    state.tags = tagsAry
  },

  SET_R3S_TAGS(state, tagsAry) {
    state.r3sTags = tagsAry
  },

  SET_USER_PERSONALIZATIONS(state, personalizations) {
    state.auth.personalizations = {
      ...state.auth.personalizations,
      ...personalizations,
    }
  },

  SET_INDEPENDENCE_STATUS(state, isIndependent) {
    state.orgIndependence = isIndependent
  },

  SET_PAGE_TITLE(state, title) {
    state.pageTitle = title
  },

  SET_REDIRECT_URL(state, url) {
    state.redirectUrl = url
  },
}
