// the name MUST match the expected filter name on the backend to work
const myControlsFilters = [
  {
    name: 'framework',
    label: 'Framework',
    component: 'DashboardFrameworkFilter',
  },
  {
    name: 'domain',
    label: 'Domain',
    component: 'DashboardDomainFilter',
  },
  {
    name: 'scope',
    label: 'Scope',
    component: 'DashboardScopeFilter',
  },
  {
    name: 'owner',
    label: 'Owner',
    component: 'DashboardOwnerFilter',
  },
  {
    name: 'tag',
    label: 'Tag',
    component: 'DashboardTagsFilter',
  },
]

const projectsFilters = [
  {
    name: 'section',
    label: 'Section',
  },
  {
    name: 'user',
    label: 'User',
  },
  {
    name: 'criticality',
    label: 'Criticality',
  },
  {
    name: 'status',
    label: 'Status',
  },
  {
    name: 'tags',
    label: 'Tags',
  },
  {
    name: 'project',
    label: 'Project',
  },
  {
    name: 'scope',
    label: 'Scope',
  },
]

const riskRegisterFilters = []

// key is the `val` from the widget
// value is the allowed filters, either the defined ones above, or a custom list
export default {
  OperationalStatus: myControlsFilters,
}
